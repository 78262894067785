<div class="modal-header">
  <div
      class="modal-icon-container"
      [style.background-color]="bgColor"
      [ngClass]="{'alter-container': alterForm}"
      >
      <ng-container *ngIf=" alterForm">
          <img src="assets/img/svgs/triangle_form.svg" alt="">
      </ng-container>

      <span class="material-symbols-outlined" [style.color]="lbColor">{{ iconName }}</span>
  </div>
  <h6 class="modal-title heading-5-bold">&nbsp;</h6>

</div>
<div class="modal-body p-5">
  <h4 class="heading-4-bold text-center mb-2">
      {{ title }}
  </h4>

  <p class="tx-body-1 text-center mb-4">
      {{ description }}
  </p>

  <div class="d-flex justify-content-around mt-2">
      <ng-container *ngIf="alterButton">
          <div class="col-6">
              <button
                  class="btn btn-outline-primary tx-button w-100"
                  (click)="responseAction( false )"
              >
                  {{ alterButton }}
              </button>
          </div>
      </ng-container>

      <div class="col-6">
          <button
              class="btn tx-button w-100"
              [ngClass]="mainButtonClass"
              (click)="responseAction( true )"
          >
              {{ mainButton }}
          </button>
      </div>
  </div>
</div>
