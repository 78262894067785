import { Routes } from "@angular/router";
import { SwitcherComponent } from "src/app/components/pages/switcher/switcher.component";

SwitcherComponent
export const SwitcherOneRoute: Routes = [
    {
        path: 'switcher-one-route' ,component:SwitcherComponent
    }
];


