import { Injectable, OnDestroy } from '@angular/core';
//import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '../toast.service';
import { ModalLoaderComponent } from '../../components/custom-modals/modal-loader/modal-loader.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class SharedDataService {

  public events = {
    token     : "token",
    app       : "app",
    data      : "data",
    nombre    : "nombre",
    usuario   : "usuario",
    company   : "company",
  }

  public access = {
    access : "access"
  }
  private modalRef!: NgbModalRef;


  constructor(
    private cookieService: CookieService,
    private modalService: NgbModal,
    private toast: ToastService ) { }

    private deletePermissionSubject = new BehaviorSubject<boolean>(false);
    deletePermission$ = this.deletePermissionSubject.asObservable();
  
    setDeletePermission(value: boolean) {
      this.deletePermissionSubject.next(value);
    }

  setLocalStorage(type: any, data: any) {
    localStorage.setItem(type, data);
  }

  getLocalStorage(type: string) {
    return localStorage.getItem(type);
  }

  clearLocalStorage(){
    localStorage.clear();
    this.cookieService.deleteAll('user', '/auth/login')
  }


  callInfoToast( medium_size: boolean = false, action_toast: boolean = false,toastTitle : string,toastLabel : string ):void {

    if ( action_toast ) {
      this.toast.showActionToast(
        toastTitle,
        toastLabel,
      );
      return;
    }

    this.toast.showCustomToast(
      toastTitle,
      toastLabel,
      undefined,
      undefined,
      undefined,
      undefined,
      medium_size
    );
  }

  callErrorToast( medium_size: boolean = false, action_toast: boolean = false,toastTitle : string,toastLabel : string ) {

    const bgColor   :string = '#F9CDBF';
    const titleColor:string = '#E83800';
    const labelColor:string = '#EE6A40';

    if ( action_toast ) {
      this.toast.showActionToast(
        toastTitle,
        toastLabel,
        undefined,
        bgColor,
        titleColor,
        labelColor,
      );
      return;
    }

    this.toast.showCustomToast(
      toastTitle,
      toastLabel,
      undefined,
      bgColor,
      titleColor,
      labelColor,
      medium_size
    );
  }

  callSuccessToast( medium_size: boolean = false, action_toast: boolean = false,toastTitle : string,toastLabel : string ) {

    const bgColor   :string = '#C8E7DE';
    const titleColor:string = '#058261';
    const labelColor:string = '#06A77D';

    if ( action_toast ) {
      this.toast.showActionToast(
        toastTitle,
        toastLabel,
        undefined,
        bgColor,
        titleColor,
        labelColor,
      );
      return;
    }

    this.toast.showCustomToast(
      toastTitle,
      toastLabel,
      undefined,
      bgColor,
      titleColor,
      labelColor,
      medium_size
    );
  }


  openVerticallyCentered() {
    this.modalRef = this.modalService.open(ModalLoaderComponent, {
      centered: true,
      backdrop: 'static',
      backdropClass: 'transparent-backdrop',
      windowClass: 'my-class'
    });
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

}
