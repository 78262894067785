import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {  Observable, throwError } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { SharedDataService } from '../shared-data/shared-data.service';
import { AuthService } from '../firebase/auth.service';
import { SwetAlertsService } from '../swetAlerts/swet-alerts.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SessionComponent } from '../../components/custom-modals/session/session.component';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  public apiToken: string = '';
  private modalRef!: NgbModalRef;

  constructor(
    private shared      : SharedDataService,
    private modalService : NgbModal,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.apiToken = this.shared.getLocalStorage(this.shared.events.token)!;

    if(this.apiToken){
      request = request.clone({
          setHeaders: {
              Authorization: `Bearer ${this.apiToken}`
          }
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          console.error('Unauthorized request:', error);
          this.modalRef = this.modalService.open(SessionComponent, {
            backdrop: 'static', // No cerrar haciendo clic fuera del modal
            centered: true,
          });
        }
        return throwError(error);
      })
    );
  }
}
