export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyCZNBTcTUcsEz74X1J45h8RZiiMrgwo12E",
    authDomain: "agendago-b6b31.firebaseapp.com",
    // databaseURL: "https://agendago-b6b31.firebaseio.com",
    // measurementId: "***********************************************",
    projectId: "agendago-b6b31",
    storageBucket: "agendago-b6b31.appspot.com",
    messagingSenderId: "706118901564",
    appId: "1:706118901564:web:5619a8370d28b9c6ea8839"
  },
  urlApi :'https://api-authentication.dev.pdctechco.com/',
  apiStorage :'https://authentication-api.dev.pdctechco.com/api/auth/login',
  urlEcosistema : "https://api-ecosistema.dev.pdctechco.com/",
  environment_name: 'develop',
};
