import { Injectable, Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActionToastrComponent } from '../layout-components/toast/action-toastr/action-toastr.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toaster: ToastrService,
  ) { }

  showCustomToast(
    title?: string,
    message?: string,
    icon_name: string = 'download',
    background_color: string = '#D7DEF2',
    title_color: string = '#2D4786',
    label_color: string = '#5E7FCD',
    medium_size: boolean = false
  ) {
    this.toaster.show(
      message,
      title,
      {
        disableTimeOut: false, // Habilitar el cierre automático
        timeOut: 5000, // Duración en milisegundos (5 segundos en este caso)
        tapToDismiss: false,
        positionClass:'toast-top-right',
        toastClass: '',
        titleClass: 'tx-body-1-bold',
        messageClass: 'tx-body-1',
        payload: {
          icon_name: icon_name,
          background_color: background_color,
          title_color: title_color,
          label_color: label_color,
          medium_size: medium_size,
        }
      }
    );
  }

  showActionToast(
    title?: string,
    message?: string,
    callback?: Function,
    icon_name: string = 'download',
    background_color: string = '#D7DEF2',
    title_color: string = '#2D4786',
    label_color: string = '#5E7FCD',
    medium_size: boolean = false,
  ) {
    this.toaster.show(
      message,
      title,
      {
        toastComponent: ActionToastrComponent,
        disableTimeOut: true,
        tapToDismiss: false,
        positionClass:'toast-top-right',
        toastClass: '',
        titleClass: 'tx-body-1-bold',
        messageClass: 'tx-body-1 m-auto',
        payload: {
          icon_name: icon_name,
          background_color: background_color,
          title_color: title_color,
          label_color: label_color,
          medium_size: medium_size
        },
      },
    ).onAction.subscribe((x) => {
      if ( callback ) {
        callback();
      }
      else {
        console.warn('No callback defined');
      }
    });
  }
}
