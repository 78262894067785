import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent {

  bgColor: string = '#FEF4D1';

  lbColor: string = '#F2C103';

  title: string = 'Sesión Expirada';

  iconName: string = 'warning';

  description: string = 'Tu sesión ha expirado, agradeceremos inicies sesión nuevamente.';

  mainButton: string = 'ok';

  mainButtonClass: string = 'btn-secondary';

  alterButton: string | undefined;

  alterForm: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    public authService: AuthService,
  ) {}

  dismissModal() {
    this.activeModal.dismiss();
  }

  responseAction( value: boolean ) {
    this.activeModal.close( value );
    this.authService.SignOut();
  }


}
