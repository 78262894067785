import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
// import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { CookieService } from 'ngx-cookie-service';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import { ToastrService } from 'ngx-toastr';
import { HttpClient,  } from '@angular/common/http';
import { Observable, lastValueFrom ,from, tap, switchMap, of, catchError} from 'rxjs';

import { HttpResponse } from 'src/app/interfaces/http-response';
import { SharedDataService } from '../shared-data/shared-data.service';
import { environment } from 'src/environments/environment';


export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  public userData: any;
  public user!: firebase.User;
  public showLoader: boolean = false;
  private inputLogin:any;
  public codeTimer: number = 0;

  //variables
  phone:any
  public token: string | undefined;
  public phoneNumber: string = '';
  public email: string = '';
  public createUserPath: string = '/Users'
  private TextCode: string = '/VerificationCode'
  public loginPath: string = '/Users/LoginUsers';

  constructor(public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private cookieService: CookieService,
    private shared: SharedDataService,
    private http:HttpClient) {

    this.afAuth.authState.subscribe((user:any) => {
      if (user) {
        this.userData = user;
        cookieService.set('user', JSON.stringify(this.userData));
        localStorage.setItem('user', JSON.stringify(this.userData));
        // JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null || '{}');
        // JSON.parse(localStorage.getItem('user'));
      }
    });
  }












  ////////////////////////////////////////////////////////


  ngOnInit(): void { }


  //aqui se envia los valores del token de login component
  setInputLogin(value: any) {
    this.inputLogin = value;
  }

  //Obtener valores de input para verifi-code
  getInputLogin(): any {
    return this.inputLogin;
  }

  sendCode(transformedData : any ){
    return this.http.post(environment.urlApi + 'service/Users/LoginUsers', transformedData).toPromise();
  }

  SendVerificationToken(data:any):Promise<any> {

    const phoneWithCode = this.inputLogin;
    this.inputLogin;
    console.log(this.inputLogin,"Valor de login de numero de telefono en el servicio")
      const jsonData= {
        Phone:phoneWithCode,
        Countrycode:`502`,
        VerCode:data
    }
  console.log( jsonData)
  return this.http.patch(environment.urlApi + 'service/VerificationCode', jsonData).toPromise()
  }


  // main verification function
  SendVerificationMail(data:any): Promise<any> {
    const phoneWithCode = this.inputLogin;
    this.inputLogin;
    const jsonData= {
      email:phoneWithCode,
      verCode:data
       }
      console.log( jsonData)

    return this.http.patch(environment.urlApi + 'service/VerificationCode', jsonData).toPromise()
  }

  getTokenUser(inputLogin : string): Promise<any>  {
    const jsonData= {
        email: inputLogin,
        password: inputLogin,
    }
    return this.http.post(environment.apiStorage, jsonData).toPromise();
  }

  getTokenSistema(): Promise<any>  {
    const jsonData= {
        email: "jose.castanaza@supertaptap.com",
        password: "patito23",
    }
    return this.http.post(environment.apiStorage, jsonData).toPromise();
  }


  setUserData(user: any) {
    console.log(user, "datos recibidos en setUserData");
    this.shared.setLocalStorage(this.shared.events.token,  user.data.token);
    this.shared.setLocalStorage(this.shared.events.data, JSON.stringify(user.data));
    this.shared.setLocalStorage(this.shared.events.nombre,  user.data.username);
    this.shared.setLocalStorage(this.shared.events.usuario, user.data.user_id);
  }

  // Sign out
  SignOut() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    return this.afAuth.signOut().then(() => {
      this.showLoader = false;
      localStorage.clear();
      this.cookieService.deleteAll('user', 'login');
      this.router.navigate(['login']);
    });
  }

   // Set user
   SetUserData(user:any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      email: user.email,
      displayName: user.displayName,
      uid: user.uid,
      photoURL: user.photoURL || 'src/favicon.ico',
      emailVerified: user.emailVerified
    };
    userRef.delete().then(function () {})
          .catch(function (error:any) {});
    return userRef.set(userData, {
      merge: true
    });
  }

 // sign in function
 postSignIn(data:any) : Promise<any>{
  const datoInput = data;
  const regexp = /^[0-9]+$/.test(datoInput);

  if (regexp ===true) {
    const transformedData = { Phone: data };
    return this.sendCode(transformedData);
  }else {
    const transformedData = { Email: data };
    return this.sendCode(transformedData);
  }
}
  // Sign in with Facebook
  signInFacebok() {
    return this.AuthLogin(new firebase.auth.FacebookAuthProvider());
  }

  // Sign in with Twitter
  signInTwitter() {
    return this.AuthLogin(new firebase.auth.TwitterAuthProvider());
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }


  // Authentication for Login
  AuthLogin(provider:any) {
    return this.afAuth.signInWithPopup(provider)
      .then((result:any) => {
        this.ngZone.run(() => {
          this.router.navigate(['/dashboard/dashboard01']);
        });
        this.SetUserData(result.user);
      }).catch((error:any) => {
        window.alert(error);
      });
  }



}
