import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';
import { SharedDataService } from '../services/shared-data/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    public authService  : AuthService,
    public shared       : SharedDataService,
    public router       : Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    let user = JSON.parse(this.shared.getLocalStorage(this.shared.events.usuario)|| 'null');

    if (!user || user === null) {
      this.router.navigate(['/auth/login']);
      return false
    }

    return true
  }

}
