<div class="horizontalMenucontainer">
    <app-switcher></app-switcher>
    <div class="page">
        <div class="">
            <!-- // header -->
            <app-header></app-header>
            <!-- //sidebar -->
            <div class="jumps-prevent" style="padding-top: 63px;"></div>
            <app-sidebar appHoverEffectSidebar></app-sidebar>
            <div class="jumps-prevent" style="padding-top: 63px;"></div>
        </div>
        <div class="main-content app-content">
            <div class="main-container container-fluid" (click)="toggleSwitcherBody()">

                <!-- // contetnt  -->
                <router-outlet >
                    <app-loader></app-loader>
                </router-outlet>
            </div>  
        </div>
        <!-- right sidebar -->
        <app-right-sidebar></app-right-sidebar>
        <!-- footer  -->
        <app-footer></app-footer>
    </div>
</div>
<app-tab-to-top></app-tab-to-top>